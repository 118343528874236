import React, {useRef, useState} from 'react';
import {useApi} from "../../api/Components/ApiProvider";
import Input from "./Input";
import History from "./History";
import Loading from "./Loading";
import config from './../../../../package.json';

const HELLO = [
    {data: " ______        ___    _  ,---.   .--.   .-_'''-.     .---.     ", date: new Date(), key: 0},
    {data: "|    _ `''.  .'   |  | | |    \\  |  |  '_( )_   \\    \\   /  ", date: new Date(), key: 1},
    {data: "| _ | ) _  \\ |   .'  | | |  ,  \\ |  | |(_ o _)|  '   |   |   ", date: new Date(), key: 2},
    {data: "|( ''_'  ) | .'  '_  | | |  |\\_ \\|  | . (_,_)/___|    \\ /   ", date: new Date(), key: 3},
    {data: "| . (_) `. | '   ( \\.-.| |  _( )_\\  | |  |  .-----.    v     ", date: new Date(), key: 4},
    {data: "|(_    ._) ' ' (`. _` /| | (_ o _)  | '  \\  '-   .'   _ _     ", date: new Date(), key: 5},
    {data: "|  (_.\\.' /  | (_ (_) _) |  (_,_)\\  |  \\  `-'`   |   (_I_)  ", date: new Date(), key: 6},
    {data: "|       .'    \\ /  . \\ / |  |    |  |   \\        /  (_(=)_) ", date: new Date(), key: 7},
    {data: "'-----'`       ``-'`-''  '--'    '--'    `'-...-'    (_I_)     ", date: new Date(), key: 8},
    {data: `v${config.version}`, date: new Date(), key: 9},
    {data: "", date: new Date(), key: 10},
]

const Terminal = () => {
    const api = useApi();
    const inputRef = useRef(null)
    const [history, setHistory] = useState(HELLO);
    const [cmds, setCmds] = useState([])
    const [cmd, setCmd] = useState('');
    const [meta, setMeta] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [pointer, setPointer] = useState(null)

    const handleClick = () => {
        inputRef.current.focus();
    }

    const handleHistory = (prev, next) => {
        let nextPos

        if (prev) {
            if (pointer === null) {
                nextPos = cmds.length - 1
            } else {
                nextPos = pointer - 1
            }
        } else if (next && pointer !== null) {
            nextPos = pointer + 1
        } else {
            nextPos = cmds.length
        }

        if (nextPos >= 0 && nextPos < cmds.length) {
            setPointer(nextPos)
            setCmd(cmds[nextPos])
        } else {
            setPointer(null)
            setCmd('')
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        setPointer(null)
        setHistory(old => [...old, { data: cmd, date: new Date() }]);

        setTimeout(()=>{
            inputRef.current.scrollIntoView({ behavior: "smooth" })
        }, 10)
        if (cmd) {

            setDisabled(true);

            const payload = meta?.cmd ? {cmd: meta.cmd, meta: {...meta, data: cmd}} : {cmd};
            if (!meta?.cmd) {
                setCmds(old => [...old, cmd])
            }

            api.execute(payload).then(({result: {data, ...newMeta}}) => {
                if (data) {
                    setHistory(old => [...old, {data, date: new Date(), hideDate: true }])
                    inputRef.current.scrollIntoView({ behavior: "smooth" })
                }
                setMeta(newMeta);
            }).catch(err => {
                setHistory(old => [...old, {data: err?.message || 'error', date: new Date(), hideDate: true }])
                inputRef.current.scrollIntoView({ behavior: "smooth" })
            }).finally(() => {
                setDisabled(false)
                inputRef.current.focus()
            })
        }

        setCmd('');
    }

    return (
        <div className="terminal" onClick={handleClick}>
            <History history={history}/>

            <form onSubmit={handleSubmit}>
                <Loading loading={disabled} />
                <Input value={cmd} onChange={setCmd} onHistory={handleHistory} ref={inputRef} disabled={disabled}/>
            </form>
        </div>
    );
}

export default Terminal;