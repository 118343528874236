const getErrorMessage = (response) => {
    if (response.status === 401) {
        return 'unauthorized'
    }

    return response.statusText;
}

export const getApi = () => {
    let token = null;

    const execute = (payload) => {
        const url = `${process.env.REACT_APP_API_URL}/cmd`;

        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: new Headers({
                'Authorization': token ? `Bearer ${token}` : undefined,
                'Content-Type': 'application/json',
            }),
        }).then(response => {
            if (response.status !== 200) {
                throw new Error(getErrorMessage(response))
            }
            return response.json();
        }).then(data => {
            if (data.result.token) {
                token = data.result.token;
            }
            return data;
        })
    }

    return {
        execute,
    }
}
