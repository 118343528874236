import React, {useContext} from "react";
import {getApi} from "../api";

const ApiContext = React.createContext(null);

export const useApi = () => useContext(ApiContext);

const ApiProvider = ({ children }) => {
    const api = getApi();

    return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
}

export default ApiProvider;