import React, {useEffect, useState} from 'react';

const STEPS = {
    '': '/', // initial step

    '/': '-',
    '-': '\\',
    '\\': '/'
}

const Loading = ({ loading }) => {
    const [step, setStep] = useState('')

    useEffect(() => {
        const handler = setTimeout(() => {
            setStep(old => STEPS[old])
        }, 120)
        return () => {
            clearTimeout(handler)
        }
    })

    return loading ? <span className="loading">{step}</span> : null;
}

export default Loading;