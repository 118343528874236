import "./App.css";
import ApiProvider from "./Modules/api/Components/ApiProvider";
import Terminal from "./Modules/terminal/Components/Terminal";

function App() {
    return (
        <ApiProvider>
            <Terminal />
        </ApiProvider>
    );
}

export default App;
