import React from "react";
import Message from "./Message";

const History = ({history}) => {
    return (
        <div className="history">
            {history.map(({data, date, hideDate, key}) => (
                <Message key={key || date.getTime()} data={data} date={date} hideDate={hideDate} />
            ))}
        </div>
    );
}

export default History;