import React, {useEffect} from 'react';

const Input = React.forwardRef(({ value, onChange, onHistory, disabled }, ref) => {
    const handleChange = (event) => {
        onChange(event.target.value)
    }

    const handleKey = (event) => {
        if (onHistory) {
            if (event.code === 'ArrowUp') {
                onHistory(true, false)
            }
            if (event.code === 'ArrowDown') {
                onHistory(false, true)
            }
            if (event.code === 'KeyC' && event.ctrlKey) {
                onHistory(false, false)
            }
        }
    }

    useEffect(() => {
        ref?.current.focus()
    }, [ref])

    return (
        <input ref={ref} value={value} onChange={handleChange} onKeyUp={handleKey} type="text" disabled={disabled} />
    );
})

export default Input;