import React from "react";
import {format} from "date-fns";

const Message = ({ data, date, hideDate }) => {
    return (
        <p key={date}>
            {!hideDate && (
                <span>{format(date, 'HH:mm:ss')}</span>
            )}
            {data}
        </p>
    );
}

export default Message;